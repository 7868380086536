


























import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';

@Component({
  components: {
    FontAwesomeComponent,
  },
})
export default class NoCommunityFound extends Vue {
  private domain = window.location.host;

  created(): void {
    if (this.$store.state.community) {
      const searchParams = new URLSearchParams(window.location.search);

      if (searchParams.has('redirect')) {
        this.$router.push({ path: `${searchParams.get('redirect')}` });
        return;
      }

      this.$router.push({ path: '/' });
    }
  }
}
